<nav class="navbar navbar-expand-lg bg-body-dark sticky-top" data-bs-theme="dark">
  <div class="container-fluid">
    @if (isAuthenticated()) {
      <div class="d-flex">
        <a [routerLink]="['/novels']"><img src="/assets/img/small-header-transparent.png" alt="Novels AI" style="max-height:38px;" /></a>
      </div>
      <div class="d-flex">
        <button type="button" class="btn btn-primary me-2" [routerLink]="['/create']">
          New Novel
        </button>
        <button 
          type="button"
          class="btn btn-success me-2 d-none d-md-inline"
          (click)="buyPoints()"
          [disabled]="paymentInProgress">Buy 10 credits</button>
        @if (!isSubscribed) {
          <button 
            type="button"
            class="btn btn-info me-2 d-none d-md-inline"
            (click)="subscribe()"
            [disabled]="paymentInProgress">Subscribe (€6.99/mo)</button>
        }
        <div class="dropdown">
          <button #accountDropdown class="btn btn-secondary dropdown-toggle" type="button" (click)="toggleAccountDropdown()" aria-expanded="false">
            Account
          </button>
          <ul #dropdownMenu class="dropdown-menu dropdown-menu-end" data-bs-theme="dark">
            <li><span class="dropdown-item-text">{{ userEmail }}</span></li>
            @if (isEmailProvider) {
              <li><a class="dropdown-item" [routerLink]="['/change-password']" (click)="closeDropdown()">Change Password</a></li>
            }
            <li><a class="dropdown-item" [routerLink]="['/voice-creator']" (click)="closeDropdown()">Voice Creator</a></li>
            <li><a class="dropdown-item" [routerLink]="['/payment-history']" (click)="closeDropdown()">Payment History</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><button class="dropdown-item" (click)="closeDropdown(); logout(accountDropdown)">Logout</button></li>
          </ul>
        </div>
      </div>
    }
    @else {
      <button type="button" class="btn btn-secondary me-3" [routerLink]="['/register']">Sign Up</button>
      <div class="d-flex">
        <button type="button" class="btn btn-primary me-3" id="navbar-signin-btn" data-bs-toggle="modal" data-bs-target="#signinModal">
          Email Login
        </button>
        <button class="btn btn-primary" [disabled]="isGoogleLoginDisabled" (click)="googleLogin()">{{ googleSignInButtonText }}</button>
      </div>
    }
  </div>
</nav>

<!-- Login Modal -->
<div 
  #signinModal 
  id="signinModal" 
  class="modal fade bg-body-dark" 
  data-bs-theme="dark"
  tabindex="-1" 
  aria-labelledby="signinModalLabel" 
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="signinModalLabel">E-mail Login</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="emailLogin()">
          <div class="input-group mb-3">
            <input id="input-email" name="email" [(ngModel)]="email" aria-describedby="emailHelp" class="form-control" type="email" placeholder="E-mail" aria-label="E-mail">
          </div>
          <div class="input-group mb-3">
            <input id="input-password" name="password" [(ngModel)]="password" class="form-control" type="password" placeholder="Password" aria-label="Password">
          </div>
          <button type="submit" class="btn btn-primary w-100" data-bs-dismiss="modal">Login</button>
        </form>
        <!-- Forgotten Password Link -->
        <div class="mt-3 text-center">
          <button type="button" class="btn btn-link" (click)="openResetPasswordModal()" data-bs-dismiss="modal">Forgot Password?</button>
        </div>
        <div style="margin-top: 15px;">
          <button type="button" class="btn btn-secondary w-100" [routerLink]="['/register']" data-bs-dismiss="modal">Not yet a member? Register here!</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Reset Password Modal -->
<div 
  #resetPasswordModal 
  id="resetPasswordModal" 
  class="modal fade bg-body-dark" 
  data-bs-theme="dark"
  tabindex="-1" 
  aria-labelledby="resetPasswordModalLabel" 
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="resetPasswordModalLabel">Reset Password</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="resetPassword()">
          <div class="input-group mb-3">
            <input id="reset-email" [(ngModel)]="resetEmail" name="resetEmail" class="form-control" type="email" placeholder="Enter your email" aria-label="Email">
          </div>
          <button type="submit" class="btn btn-primary w-100" data-bs-dismiss="modal">Send Password Reset Email</button>
        </form>
      </div>
    </div>
  </div>
</div>
<app-confirmation-modal #cancelSubscriptionModal title="Cancel Subscription"></app-confirmation-modal>
<app-subscription-benefits-modal #subscriptionBenefitsModal></app-subscription-benefits-modal>

